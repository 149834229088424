import { connect } from 'react-redux'
import WeGiveApp from './WeGiveApp.jsx'

const mapStateToProps = (state, ownProps) => {
  return ({
    preferedEmail: ownProps.emailAddress ? ownProps.emailAddress : '',
    postSuccess: state.postSuccess,
    submitting: state.submitting,
    ...ownProps
  })
}

const dispatchStateToProps = (dispatch) => ({
  postDataToDB: (data) => dispatch({
    type: 'POST_DATA_TO_DB',
    data: data
  }),
  resubmit: () => dispatch({
    type: 'RESUBMIT'
  })
})

export default connect(mapStateToProps, dispatchStateToProps)(WeGiveApp)
