export const testPledges = [
    {
        "firstName": "Firstname",
        "lastName": "Lastname",
        "opid": "FXL000",
        "emailAddress": null,
        "workSite": null,
        "fundId": "34-0714455",
        "fundName": "United Way of Richland County",
        "fundCode": "UW",
        "donationCount": 1,
        "donationAmount": 90
    },
    {
        "firstName": "Firstname",
        "lastName": "Lastname",
        "opid": "FXL000",
        "emailAddress": null,
        "workSite": null,
        "fundId": "70001",
        "fundName": "OhioHealth Greatest Need",
        "fundCode": "FND",
        "donationCount": 1,
        "donationAmount": 9
    },
    {
        "firstName": "Firstname",
        "lastName": "Lastname",
        "opid": "FXL000",
        "emailAddress": null,
        "workSite": null,
        "fundId": "70001",
        "fundName": "OhioHealth Greatest Need",
        "fundCode": "FND",
        "donationCount": 1,
        "donationAmount": 60
    },
    {
        "firstName": "Firstname",
        "lastName": "Lastname",
        "opid": "FXL000",
        "emailAddress": null,
        "workSite": null,
        "fundId": "75007",
        "fundName": "OhioHealth Associate Emergency Assistance Fund",
        "fundCode": "FND",
        "donationCount": 1,
        "donationAmount": 90
    },
    {
        "firstName": "Firstname",
        "lastName": "Lastname",
        "opid": "FXL000",
        "emailAddress": null,
        "workSite": null,
        "fundId": "34-0714455",
        "fundName": "United Way of Richland County",
        "fundCode": "UW",
        "donationCount": 1,
        "donationAmount": 60
    },
    {
        "firstName": "Firstname",
        "lastName": "Lastname",
        "opid": "FXL000",
        "emailAddress": null,
        "workSite": null,
        "fundId": "75064",
        "fundName": "Provider and Associate Well-being",
        "fundCode": "FND",
        "donationCount": 26,
        "donationAmount": 90
    }
]