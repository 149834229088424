export const UWPriortyFunds = [
    {
        'fundId': '31-4393712',
        'fundName': 'United Way of Central Ohio'
    },
    {
        'fundId': '31-4423899',
        'fundName': 'United Way of Delaware County'
    },
    {
        'fundId': '31-0641236',
        'fundName': 'United Way of North Central Ohio (Crawford, Marion, Morrow & Wyandot Counties)'
    },
    {
        'fundId': '34-0714455',
        'fundName': 'United Way of Richland County'
    },
    {
        'fundId': '34-1022412',
        'fundName': 'United Way of Hardin County'
    },
    {
        'fundId': '31-0644804',
        'fundName': 'United Way of Fairfield County'
    },
    {
        'fundId': '31-1777036',
        'fundName': 'Pickaway County Community Foundation'
    },
    {
        'fundId': '34-0891893',
        'fundName': 'United Way of Van Wert County'
    },
    {
        'fundId': '31-6401367',
        'fundName': 'United Way of Guernsey, Monroe and Noble Counties'
    },
    {
        'fundId': '31-0718322',
        'fundName': 'Southeast Ohio Foodbank & Kitchen of Hocking Athens Perry Community Action'
    }
]

export const OhioHealthFoundationFunds = [
    {
        "fundName": "Access to Healthcare for Underserved Communities",
        "fundId": "75058",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Alex's Sunnyside Playroom",
        "fundId": "4467",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Berger Hospital Cancer Care",
        "fundId": "43503",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Berger Hospital Greatest Need",
        "fundId": "43001",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Berger Hospital Nursing Scholarship",
        "fundId": "43500",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Berger Hospital Provider and Associate Well-being",
        "fundId": "43509",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Berger Hospital Women's Health Fund",
        "fundId": "43512",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Bing Cancer Center",
        "fundId": "4428",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Blood and Marrow Transplant Program",
        "fundId": "4473",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Scalp Cooling Therapy",
        "fundId": "75061",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital Cancer Program",
        "fundId": "55020",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital Greatest Need",
        "fundId": "50001",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital Healing Garden",
        "fundId": "55044",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital Heart Care",
        "fundId": "55022",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital Nursing Excellence",
        "fundId": "55002",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital Patient Assistance",
        "fundId": "55005",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital Provider and Associate Well-being",
        "fundId": "55049",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital Residency Education",
        "fundId": "55019",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital Womens Health Services",
        "fundId": "55007",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Dublin Cancer Care",
        "fundId": "85011",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Dublin Methodist Hospital Family Medicine Residency Practice Fund",
        "fundId": "85015",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Dublin Methodist Hospital Greatest Need",
        "fundId": "85001",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Dublin Methodist Hospital Heart Services",
        "fundId": "85010",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Dublin Methodist Hospital Nursing Excellence",
        "fundId": "85005",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Dublin Methodist Hospital Patient Assistance Fund",
        "fundId": "85009",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Dublin Methodist Hospital Provider and Associate Well-being",
        "fundId": "85022",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Dublin Methodist Hospital Women's Health",
        "fundId": "85007",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Food is Health",
        "fundId": "24890",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grady Memorial Hospital Cancer Care",
        "fundId": "95233",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grady Memorial Hospital Cardiac Services",
        "fundId": "95247",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grady Memorial Hospital Greatest Need",
        "fundId": "90105",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grady Memorial Hospital Nursing Excellence",
        "fundId": "95241",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grady Memorial Hospital Provider and Associate Well-being",
        "fundId": "95622",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grady Memorial Hospital Rehabilitation Services",
        "fundId": "95238",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grady Memorial Hospital Women's Health Services",
        "fundId": "95260",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grant Medical Center Addiction Medicine Fellowship",
        "fundId": "24884",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grant Medical Center Cancer Care",
        "fundId": "24713",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grant Medical Center Emergency Department",
        "fundId": "24744",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grant Medical Center Family Practice Medical Education",
        "fundId": "24708",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grant Medical Center Greatest Need",
        "fundId": "14701",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grant Medical Center Heart Care",
        "fundId": "24778",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grant Medical Center Nursing Excellence",
        "fundId": "24723",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grant Medical Center Pharmacy Research and Education",
        "fundId": "24710",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grant Medical Center Provider and Associate Well-being ",
        "fundId": "24893",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grant Medical Center Trauma Department",
        "fundId": "24747",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grant Medical Center Women's Health Services ",
        "fundId": "24773",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grove City Methodist Hospital Greatest Needs",
        "fundId": "14702",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Hardin Memorial Hospital Greatest Needs",
        "fundId": "67001",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Hardin Memorial Hospital Provider and Associate Well-being",
        "fundId": "67002",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Community Grief Bereavement Dog",
        "fundId": "65016",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Hospice Quality of Life - Final Wishes Fund",
        "fundId": "65009",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Kanesha Turner Moss Nursing Scholarship",
        "fundId": "85020",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Kobacker House Support (Hospice)",
        "fundId": "65020",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Lawson Chronic Disease Education Fund",
        "fundId": "24793",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "LGBTQ Community Programs",
        "fundId": "75051",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Mammography Screening Fund for Underserved",
        "fundId": "75016",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Mansfield Hospital Cancer Cancer Care",
        "fundId": "41501",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Mansfield Hospital Greatest Need Fund",
        "fundId": "41500",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Mansfield Hospital Heart Services",
        "fundId": "41505",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Mansfield Hospice Greatest Needs",
        "fundId": "41507",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Mansfield Hospital Juvenile Diabetes Fund",
        "fundId": "41508",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Mansfield Hospital Nursing Scholarship General Fund",
        "fundId": "41510",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Mansfield Hospital Patient Assistance Fund",
        "fundId": "41511",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Mansfield Hospital Provider and Associate Well-being",
        "fundId": "41522",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Mansfield Hospital Women's Health",
        "fundId": "41513",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Marion General Hospital Cancer Care",
        "fundId": "48500",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Marion General Hospital Greatest Need",
        "fundId": "48002",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Marion General Hospital Patient Assistance",
        "fundId": "48006",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Marion General Hospital Provider and Associate Well-being",
        "fundId": "48007",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "McConnell Heart Care at Riverside",
        "fundId": "4402",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "McConnell Heart Health Center Programs/Research/Development",
        "fundId": "4395",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "McConnell Heart Health Center Campus and Expansion Fund",
        "fundId": "04407",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Medical Education Fund for Pickerington Medical Campus",
        "fundId": "24753",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Medical Education Fund for Westerville Medical Campus",
        "fundId": "24752",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Mom and Baby Mobile Health Center",
        "fundId": "75041",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Mothers' Milk Bank of Ohio",
        "fundId": "24780",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Mothers with Substance Abuse",
        "fundId": "75042",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Morrow County Hospital Greatest Needs",
        "fundId": "21501",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Morrow County Hospital Nursing Excellence",
        "fundId": "21502",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Multiple Sclerosis Patient Assistance Fund",
        "fundId": "75036",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Neuroscience Wellness Center",
        "fundId": "75053",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Neuroscience Wellness Center Accessibility Fund",
        "fundId": "75105",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "O'Bleness Hospital- OhioHealth at Home and Athens Hospice",
        "fundId": "42500",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "O'Bleness Family Medicine Residency Program",
        "fundId": "42615",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "O'Bleness Hospital Cancer Care",
        "fundId": "42610",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "O'Bleness Hospital Greatest Need",
        "fundId": "42601",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "O'Bleness Hospital Healthy Moms and Babies",
        "fundId": "42608",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "O'Bleness Hospital Heart Care",
        "fundId": "42609",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "O'Bleness Hospital Nursing Excellence",
        "fundId": "42607",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OBleness Hospital Patient Assistance Fund",
        "fundId": "42606",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "O'Bleness Hospital Provider and Associate Well-being",
        "fundId": "42620",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Administrative Fellowship Leadership Development Fund",
        "fundId": "75055",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Ambulatory Care Management Fund",
        "fundId": "75028",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Ambulatory Neuro Rehab",
        "fundId": "4123",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Associate Emergency Assistance Fund",
        "fundId": "75007",
        "priorityInfo": {
            "isPriority": true,
            "sortOrder": 1
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Cancer Care",
        "fundId": "75022",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Community Outreach",
        "fundId": "24741",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Environmental Sustainability",
        "fundId": "75069",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Greatest Need",
        "fundId": "70001",
        "priorityInfo": {
            "isPriority": true,
            "sortOrder": 4
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Health Equity",
        "fundId": "75065",
        "priorityInfo": {
            "isPriority": true,
            "sortOrder": 3
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Community Grief Fund",
        "fundId": "65011",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Hospice Greatest Needs",
        "fundId": "65001",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Medical Education",
        "fundId": "75015",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Mission & Ministry",
        "fundId": "75002",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Neuroscience ALS General",
        "fundId": "75059",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Nursing Excellence",
        "fundId": "75024",
        "priorityInfo": {
            "isPriority": true,
            "sortOrder": 2
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Pharmacy Education Fund",
        "fundId": "75124",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Sports Medicine",
        "fundId": "75018",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Physician Diversity Scholars Program",
        "fundId": "75029",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Provider and Associate Well-being",
        "fundId": "75064",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Riverside Methodist Hospital Emergency Services",
        "fundId": "4124",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Riverside Methodist Hospital Family Practice Residency",
        "fundId": "4101",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Riverside Methodist Hospital Greatest Need",
        "fundId": "1103",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Riverside Methodist Hospital Cancer Care",
        "fundId": "4410",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Riverside Methodist Hospital Patient Assistance",
        "fundId": "3375",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Riverside Methodist Hospital Provider and Associate Well-being",
        "fundId": "4477",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Riverside Methodist Hospital Spirit of Nursing",
        "fundId": "4153",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Riverside Methodist Hospital Women's Health",
        "fundId": "4151",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Robert L. Thomas, DO Anesthesiology Education",
        "fundId": "55013",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Sexual Assault Response Network of Central Ohio (SARNCO)",
        "fundId": "4356",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Senior Health Services at Riverside",
        "fundId": "4128",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Shelby Cancer Care Fund",
        "fundId": "41514",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Shelby Hospital Greatest Need Fund",
        "fundId": "41515",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Tokarski Family NICU \"Stories for Hope\"",
        "fundId": "4476",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Vascular Institute",
        "fundId": "75001",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Vascular Institute Research",
        "fundId": "75033",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Mobile Primary Care Unit",
        "fundId": "75045",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Wellness Programs and Services",
        "fundId": "75050",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Clinical Enterprise Physician Leadership Development",
        "fundId": "75107",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "O'Bleness Hospital Peer Recovery",
        "fundId": "42614",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grant Medical Center Medical Respite Care",
        "fundId": "24889",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Marion General Hospital Pastoral Support",
        "fundId": "48000",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Mansfield Hospital Mother/Baby Fund",
        "fundId": "41509",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Mansfield Hospital Trauma Services General Fund",
        "fundId": "41518",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "North Region Mobile Mammography Fund",
        "fundId": "41521",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital Education",
        "fundId": "55016",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Dublin Methodist Hospital Community Health Education",
        "fundId": "85004",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Dublin Methodist Hospital Compassion & Dignity Fund",
        "fundId": "85018",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "The Bates Family Celebration Fund",
        "fundId": "85030",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grady Memorial Hospital Patient Assistance Fund",
        "fundId": "95234",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grady Memorial Hospital Medical Education",
        "fundId": "95258",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Biomedical Science STEM Program",
        "fundId": "24887",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Dublin Scalp Cooling Therapy",
        "fundId": "85021",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Pickerington Hospital Greatest Needs",
        "fundId": "24795",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Women's Health Greatest Needs",
        "fundId": "75110",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Heart and Vascular Greatest Needs",
        "fundId": "75111",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Southeastern Medical Center Cancer Services",
        "fundId": "15501",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Southeastern Medical Center Heart Services",
        "fundId": "15505",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Southeastern Medical Center Patient Assistance Fund",
        "fundId": "15502",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Southeastern Medical Center Nursing Excellence",
        "fundId": "15506",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Southeastern Medical Center Greatest Needs",
        "fundId": "15001",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Dublin Methodist Hospital Trauma Services",
        "fundId": "85024",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Van Wert Health Greatest Needs",
        "fundId": "17000",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Southeastern Medical Center Provider and Associate Well-being",
        "fundId": "15507",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OPG Greatest Needs",
        "fundId": "72000",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grove City Methodist Hospital Provider and Associate Well-being",
        "fundId": "14501",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Urology Fund",
        "fundId": "75116",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Orthopedics Fund",
        "fundId": "75117",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "John F. Tugaoen, MD Memorial Endowed Fund",
        "fundId": "3762",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital Family Medicine Residency",
        "fundId": "55041",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital Internal Medicine Residency",
        "fundId": "55046",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital General Surgery Residency",
        "fundId": "55047",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital Sterile Processing Professional Development ",
        "fundId": "55040",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Foundation Digestive Disease Fund",
        "fundId": "24745",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital Orthopedic Residency",
        "fundId": "55024",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital Ophthalmology Residency Support",
        "fundId": "55036",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital Emergency Medicine Residency Support",
        "fundId": "55038",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital Otolaryngology(ENT) Residency Support",
        "fundId": "55039",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital OB / GYN Residency Support",
        "fundId": "55045",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital Cardiology Fellowship",
        "fundId": "55048",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital Neurosurgical Residency Support",
        "fundId": "55026",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Wolf Family Nursing Scholarship",
        "fundId": "24896",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Neuro Critical Care Program(NCC)",
        "fundId": "75137",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Neuroscience Transportation Fund",
        "fundId": "75138",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grant Medical Center Patient Assistance Fund",
        "fundId": "3368",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grant Medical Center Chartiy Care - Outpatient Best Use",
        "fundId": "3373",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grant Podiatry",
        "fundId": "24783",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grant Neurosurgery Residency Program",
        "fundId": "24784",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grant Medical Center Medical Education",
        "fundId": "24770",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital Pharmacy Education",
        "fundId": "55017",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "EMS Education",
        "fundId": "24734",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "The Brooke A.Bundy Memorial Fund",
        "fundId": "4500",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Trauma Services at Dublin Methodist Hospital",
        "fundId": "85025",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Doctors Hospital Influsion Clinic",
        "fundId": "55052",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Adaptive Recreational Program",
        "fundId": "75115",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Fore Hope - Guy Derr & Mindy Derr Endowment",
        "fundId": "73020",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "New Albany / Westerville Breast Cancer Patients",
        "fundId": "19501",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Neuro Wellness Center Behavioral Health and Wellness",
        "fundId": "75118",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Southeastern Medical Center Cardiopulmonary Rehabilitation",
        "fundId": "15508",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Nursing Equity Scholarship Fund",
        "fundId": "75130",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Pickerington Methodist Hospital Greatest Needs",
        "fundId": "49500",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Medical Education Fund for OH Pickerington Medical Campus",
        "fundId": "49501",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Pickerington Nursing Education",
        "fundId": "49503",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Bringing Up Baby with Books",
        "fundId": "42623",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Colorectal Cancer Fund",
        "fundId": "75131",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "BMT & Blood Disorders Fund",
        "fundId": "75132",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Women's Center Community Clinic",
        "fundId": "4505",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grove City Methodist Nursing Excellence",
        "fundId": "14502",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Van Wert Hospital Nursing Excellence",
        "fundId": "17502",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Shelby Hospital Nursing Excellence",
        "fundId": "41524",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Marion General Nursing Excellence",
        "fundId": "48501",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Hardin Memorial Nursing Excellence",
        "fundId": "67004",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "NICU Support Fund",
        "fundId": "4506",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Berger Patient Assistance",
        "fundId": "43516",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Neuroscience Educational Fund",
        "fundId": "75135",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "OhioHealth Menopause and Midlife Clinic",
        "fundId": "75136",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Southeastern Medical Center Bereavement Fund",
        "fundId": "15509",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Riverside Behavioral Medicine",
        "fundId": "4120",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Neuroscience MS General Purpose Departmental",
        "fundId": "4461",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Stroke Prevention Clinic",
        "fundId": "75039",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Movement Disorders Community & Education",
        "fundId": "75046",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Mobile Stroke Treatment Unit Fund",
        "fundId": "75133",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Real Estate, Construction and Real Estate Scholarship Fund",
        "fundId": "75141",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Dublin Methodist School Based Health Program",
        "fundId": "85032",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Grant Equipment & Capital Projects",
        "fundId": "24775",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Jane S. Kenney Thoracic Oncology Support",
        "fundId": "24895",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Warren Yamarick, MD Emergency Department Endowed Nursing Scholarship",
        "fundId": "03758",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    },
    {
        "fundName": "Riverside CME&I/Medical Education",
        "fundId": "04102",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    }
]


/* Fund Template
    {
        "fundName": "",
        "fundId": "",
        "priorityInfo": {
            "isPriority": false,
            "sortOrder": null
        },
        "sortOrder": null
    }        
*/