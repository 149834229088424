import { Fragment } from 'react'
import ItemSeparator from './itemSeparator.jsx'
import { currencyFormatter } from '../../shared/utility-functions/currencyFormatter.js'
import './pledgeSummary.scss'

const PledgeSummary = ({ charityPledgeCollection, perPayPeriodValue, oneTimeValue, perPayPeriodSummaryNote, oneTimeSummaryNote }) => {
    const perPayPeriodPledges = charityPledgeCollection
        .map(i => i.pledges.filter(j => j.donationCount === perPayPeriodValue).map(k => k.donationAmount))
        .flat(1)
    const oneTimePledges = charityPledgeCollection
        .map(i => i.pledges.filter(j => j.donationCount === oneTimeValue).map(k => k.donationAmount))
        .flat(1)
    const totalPledges = charityPledgeCollection
        .map(i => i.pledges.map(k => k.donationAmount))
        .flat(1)
    const perPeriodAmount = perPayPeriodPledges.reduce((acc, item) => acc + item, 0)
    const oneTimeAmount = oneTimePledges.reduce((acc, item) => acc + item, 0)
    const noteSubString = 'Deductions will appear on your payslip'

    const getNote = () => {
        const charities = charityPledgeCollection
            .filter(i => i.pledges.some(j => j.donationAmount > 0))
            .map(i => ({ name: i.charityName, abbr: i.charityAbbreviation }))

        if (charities.length === 0) {
            return `${noteSubString}.`
        }
        else if (charities.length === 1) {
            return `${noteSubString} as ${charities[0].abbr} (${charities[0].name}).`
        }
        else if (charities === 2) {
            return `${noteSubString} as ${charities[0].abbr} (${charities[0].name}) and ${charities[1].abbr} (${charities[1].name}).`
        }
        else {
            const stringList = charities.map(i => `${i.abbr} (${i.name})`)

            return `${noteSubString} as ${stringList.slice(0, -1).join(', ')} and ${stringList[stringList.length - 1]}.`
        }
    }

    return (
        <Fragment>
            <div className='pledge-summary-title'>Your Pledge Summary</div>
            <div className='pledge-summary'>
                {
                    charityPledgeCollection.map((item, i) => (
                        <div>
                            <div className='pledge-charity-name'>{item.charityName}</div>
                            <div className='pledge-charity-pledges'>
                                {
                                    item.pledges.map((pledge, j) => (
                                        pledge.donationAmount > 0 &&
                                        <div key={`${i}-${j}`} className='pledge-charity-item'>
                                            <div className='pledge-charity-item-fund'>{pledge.fundName}</div>
                                            <div className='pledge-charity-item-amount'>
                                                {currencyFormatter().format(pledge.donationAmount)}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                } 
                <ItemSeparator width='100%' margin='25px auto' color='#333' />
                <div className='pledge-summary-total'>
                    <div className='pledge-summary-total-label'>Total WE GIVE Pledge</div>
                    <div className='pledge-summary-total-amount'>
                        {currencyFormatter().format(totalPledges.reduce((acc, item) => acc + item, 0))}
                    </div>
                </div>
                <div className='pledge-summary-message'>{getNote()}</div>                
                <div>
                    {
                        oneTimeAmount > 0 &&
                        <Fragment>
                            <div className='pledge-summary-total'>
                                <div className='pledge-summary-total-label'>One-Time Pledge</div>
                                <div className='pledge-summary-total-amount'>
                                    {currencyFormatter().format(oneTimeAmount)}
                                </div>
                            </div>
                            <div className='pledge-summary-note'>{oneTimeSummaryNote}</div>
                        </Fragment>
                    }
                    {
                        perPeriodAmount > 0 &&
                        <Fragment>
                            <div className='pledge-summary-total'>
                                <div className='pledge-summary-total-label'>Per Pay Period Pledge</div>
                                <div className='pledge-summary-total-amount'>
                                    {currencyFormatter().format(perPeriodAmount / perPayPeriodValue)}
                                </div>
                            </div>
                            <div className='pledge-summary-note'>{perPayPeriodSummaryNote}</div>
                        </Fragment>
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default PledgeSummary