import SVGInline from 'react-svg-inline'

import './actionButton.scss'

const ActionButtonLink = ({ classes, icon, text, isCustom, actionType, dataAttributes, handleClick }) => {
    return (
        <div
            className={`action-button-link ${classes}${isCustom ? ' custom' : ''}`}
            name={`action-${actionType}`}
            data-action={actionType}
            data-code={dataAttributes.code}
            data-current={dataAttributes.current}
            data-priority={dataAttributes.priority}
            data-custom={dataAttributes.custom}
            onClick={handleClick}
        >
            <SVGInline className='icon' svg={icon} />
            <span className='action-button-link-label'>{text}</span>
        </div>
    )
}

export default ActionButtonLink