import React from 'react'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import multi from 'redux-multi'
import WeGiveReducer from './WeGiveReducer'
import WeGiveSaga from './we-give-saga'
import WeGiveAppConnector from './WeGiveAppConnector.jsx'

export default class WeGiveRoot extends React.Component {
  constructor (props) {
    super(props)
    const sagaMiddleware = createSagaMiddleware()
    this.weGiveStore = createStore(WeGiveReducer, composeWithDevTools(applyMiddleware(sagaMiddleware, multi)))
    sagaMiddleware.run(WeGiveSaga)
  }

  render () {
    return (
      <Provider store={this.weGiveStore}>
        <WeGiveAppConnector firstName={this.props.firstName} lastName={this.props.lastName} emailAddress={this.props.emailAddress} opid={this.props.opid} />
      </Provider>
    )
  }
}
