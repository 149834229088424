import { race, put, take, delay } from 'redux-saga/effects'
import { LONG_TIMEOUT } from '../shared/constants/wait-times'
import { sendWeGiveEmail } from './services/we-give-service'

export default function * weGiveSaga (waitTime = LONG_TIMEOUT) {
  while (true) {
    let action = yield take('POST_DATA_TO_DB')
    let data = action.data ? Object.assign({}, action.data) : {}

    const { posts } = yield race({
      posts: sendWeGiveEmail(data),
      timeout: delay(waitTime)
    })

    if (posts && posts.success) {
      yield put({ type: 'POST_SUCCEEDED', value: true })
    } else {
      yield put({ type: 'POST_SUCCEEDED', value: false })
    }
  }
}
