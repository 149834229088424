import { WeGivePledgeRow, WeGiveCustomPledgeRow, WeGiveFakeSelectPledgeRow } from './weGivePledgeRows.jsx'
import { isNullOrWhitespace } from '../../shared/utility-functions/stringUtils.js'
import ActionButtonLink from './actionButton.jsx'
import './pledgeComponents.scss'

export const PledgeComponent = ({
    heading,
    comboHeading,
    label,
    pledgeList,
    selectOptions,
    selectPlaceholderValue,
    addPledgeControlOptions,
    removePledgeControlOptions,
    deductionIntervalControlOptions,
    maxPledgeCount,
    handleChange,
    handleClick,
    submitted,
    pledgeErrors, 
    selectPlaceholderLabel = null,
    isComboDisplay = false
}) => {
    return (
        <div className='pledge-section'>
            <div className='subheading'>{isComboDisplay ? comboHeading : heading}</div>
            <div>
                {pledgeList.map((pledge, i) => {
                    const exclusions = pledgeList.filter(p => p.fundId !== pledge.fundId).map(p => p.fundId)

                    return (
                    <WeGivePledgeRow
                        key={i}
                        pledgeCount={i}
                        pledge={pledge}
                        selectControlOptions={{
                            label: label,
                            classes: '',
                            placeHolderOptions: {
                                hasPlaceholder: true,
                                classes: 'default',
                                value: selectPlaceholderValue,
                                label: isNullOrWhitespace(selectPlaceholderLabel) ? label : selectPlaceholderLabel
                            },
                            options: selectOptions.filter(option => !exclusions.includes(option.fundId))
                        }}
                        currencyOptions={{
                            label: 'Total Annual Amount'
                        }}
                        removePledgeControlOptions={removePledgeControlOptions}
                        deductionIntervalControlOptions={deductionIntervalControlOptions}
                        handleChange={handleChange}
                        handleClick={handleClick}
                        submitted={submitted}
                        pledgeErrors={pledgeErrors.find(j => j.fundId === pledge.fundId)}
                    />)
                })}
            </div>
            {(pledgeList.length < maxPledgeCount) &&
                <ActionButtonLink
                    classes={addPledgeControlOptions.classes}
                    icon={addPledgeControlOptions.icon}
                    text={addPledgeControlOptions.text}
                    actionType={addPledgeControlOptions.actionType}
                    dataAttributes={{
                        code: pledgeList[0] ? pledgeList[0].fundCode : '',
                        current: '',
                        priority: pledgeList.length > 0 ? pledgeList[0].isPriority : false,
                        custom: false
                    }}
                    handleClick={handleClick}
                />
            }
        </div>
    )
}

export const CustomFakeSelectPledgeComponent = ({
    heading,
    comboHeading,
    countOffset,
    label,
    pledgeList,
    selectOptions,
    addPledgeControlOptions,
    removePledgeControlOptions,
    deductionIntervalControlOptions,
    maxPledgeCount,
    handleChange,
    handleClick,
    submitted,
    pledgeErrors,
    placeholderLabel = null,
    isComboDisplay = false
}) => {
    return (
        <div className='pledge-section'>
            <div className='subheading'>{isComboDisplay ? comboHeading : heading}</div>
            <div>
                {pledgeList.map((pledge, i) => {
                    const exclusions = pledgeList.filter(p => p.fundId !== pledge.fundId).map(p => p.fundId)

                    return (
                        <WeGiveFakeSelectPledgeRow
                            key={i}
                            pledgeCount={i}
                            countOffset={countOffset}
                            pledge={pledge}
                            fakeSelectControlOptions={{
                                label: label,
                                placeholder: placeholderLabel,
                                options: selectOptions.filter(option => !exclusions.includes(option.fundId))
                            }}
                            currencyOptions={{
                                label: 'Total Annual Amount'
                            }}
                            removePledgeControlOptions={removePledgeControlOptions}
                            deductionIntervalControlOptions={deductionIntervalControlOptions}
                            handleChange={handleChange}
                            handleClick={handleClick}
                            submitted={submitted}
                            pledgeErrors={pledgeErrors.find(j => j.fundId === pledge.fundId)}
                        />)
                })}
            </div>
            {(pledgeList.length < maxPledgeCount) && (pledgeList.filter(i => i.fundId === '-1' && !i.isPriority).length === 0) &&
                <ActionButtonLink
                    classes={addPledgeControlOptions.classes}
                    icon={addPledgeControlOptions.icon}
                    text={addPledgeControlOptions.text}
                    actionType={addPledgeControlOptions.actionType}
                    dataAttributes={{
                        code: pledgeList[0] ? pledgeList[0].fundCode : '',
                        current: '',
                        priority: pledgeList.length > 0 ? pledgeList[0].isPriority : false,
                        custom: false
                    }}
                    handleClick={handleClick}
                />
            }
        </div>
    )
}

export const CustomPledgeComponent = ({
    countOffset,
    controlOptions,
    addPledgeControlOptions,
    removePledgeControlOptions,
    deductionIntervalControlOptions,
    pledges,
    maxPledgeCount,
    handleClick,
    handleChange,
    pledgeErrors,
    submitted
}) => {
    return (
        <div className='pledge-custom-section'>
            <div className='subheading'></div>
            <div>
                {pledges.map((pledge, i) =>
                    i < maxPledgeCount &&
                    <WeGiveCustomPledgeRow
                        key={i}
                        countOffset={countOffset}
                        pledgeCount={++i}
                        controlOptions={controlOptions}
                        pledge={pledge}
                        removePledgeControlOptions={removePledgeControlOptions}
                        deductionIntervalControlOptions={deductionIntervalControlOptions}
                        handleChange={handleChange}
                        handleClick={handleClick}
                        pledgeErrors={pledgeErrors.find(j => j.fundId === pledge.fundId)}
                        submitted
                    />
                )}
            </div>
            {(pledges.length < maxPledgeCount) && pledges.every(p => !['-1', '0', ''].includes(p.fundId)) &&
                <ActionButtonLink
                    classes={addPledgeControlOptions.classes}
                    icon={addPledgeControlOptions.icon}
                    text={addPledgeControlOptions.text}
                    actionType={addPledgeControlOptions.actionType}
                    dataAttributes={{
                        code: pledges[0] ? pledges[0].fundCode : '',
                        current: '',
                        priority: pledges.length > 0 ? pledges[0].isPriority : false,
                        custom: true
                    }}
                    handleClick={handleClick}
                />
            }
        </div>
    )
}