import { isNullOrWhitespace } from "../../shared/utility-functions/stringUtils"

const ItemSeparator = ({ width, margin, color = null }) => {
    return (
        <div
            className='item-separator'
            style={`width:${width};margin:${margin};border-bottom: 1px solid ${isNullOrWhitespace(color) ? '#cacaca' : color} ;text-align: center;`}>
        </div>
    )
}

export default ItemSeparator