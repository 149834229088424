import { websiteAPI } from '../../shared/services/api'

const WE_GIVE_ENDPOINT = '/api/wegive/postdata'
const WE_GIVE_GET_PLEDGES_ENDPOINT = '/api/wegive/getpledges'

const sendWeGiveEmail = async (data) => {
  return postRequest(WE_GIVE_ENDPOINT, data, '')
}

const getWeGivePledges = async (data) => {
    return postGetPledgesRequest(WE_GIVE_GET_PLEDGES_ENDPOINT, data, '')
}

const postRequest = async (webServiceEndpoint, data, header) => {
  try {
    const response = await websiteAPI.post(webServiceEndpoint, data, header)
    return response.data
  } catch (e) {
    return new Error('An unexpected error occurred')
  }
}

const postGetPledgesRequest = async (webServiceEndpoint, data, header) => {
    try {
        const response = await websiteAPI.post(webServiceEndpoint, data, header)
        return response.data
    }
    catch (e) {
        return new Error('An unexpected error occurred')
    }
  //return postRequest('/api/pledges', {})
}

export { WE_GIVE_ENDPOINT, sendWeGiveEmail, postRequest, getWeGivePledges }
