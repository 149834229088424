import SVGInline from 'react-svg-inline'
import './postResult.scss'

const PostResult = ({ weGiveLogo, icon, iconParentClasses, iconClasses, children }) => {
    return (
        <div className='post-result-page'>
            <div className='light-blue-container'>
                <div className={iconParentClasses}>
                    <SVGInline className={iconClasses} svg={icon} />
                </div>
                {children}
                <div className='contact-us'>Contact us: <a href='mailto:wegive@ohiohealth.com'>WEGive@OhioHealth.com</a></div>
            </div>
            <img className='we-give-logo' src={weGiveLogo} alt='We Give Logo' />
        </div>
    )
}

export default PostResult