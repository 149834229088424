export const DEFAULT_STATE = {
  postSuccess: null,
  submitting: false
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'POST_SUCCEEDED': return { ...state, ...{ postSuccess: action.value, submitting: false } }
    case 'POST_DATA_TO_DB': return { ...state, ...{ submitting: true } }
    case 'RESUBMIT': return { ...state, ...{ submitting: true } }
  }
  return state
}
